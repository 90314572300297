//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ContentEditor from "@/components/admin/pages/ContentEditor";

export default {
  name: "PostTranslatableFields",
  components: {ContentEditor},
  props: {
    language: String,
    translate: Object
  },
  data() {
    return {
      title_label: null,
      description_label: null,
      content_label: null
    }
  },
  model: {
    prop: 'translate',
    event: 'post-created',
  },
  created() {
    if (this.language === 'eng') {
      this.title_label = 'Title'
      this.description_label = 'Description'
      this.content_label = 'Content'
    } else {
      this.title_label = 'Заголовок'
      this.description_label = 'Описание'
      this.content_label = 'Контент'
    }
  }
}
