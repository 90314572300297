//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Screenshots",
  data() {
    return {
      screenshots: [],
      addedScreenshots: [],
      filters: {
        byDate: 'old',
        name: ''
      }
    }
  },
  methods: {
    add(screenshot) {
      if (!this.isSameScreenshotAlreadyAdded(screenshot)) {
        this.addedScreenshots.push(screenshot)
      } else {
        this.$toast.info('Такой скриншот уже добавлен')
      }

      this.setScreenshotIds()
    },
    isSameScreenshotAlreadyAdded(screenshot) {
      let result = false

      this.addedScreenshots.forEach((addedScreenshot) => {
        if (JSON.stringify(addedScreenshot) === JSON.stringify(screenshot)) result = true
      })

      return result
    },
    getAbsoluteScreenshotUrl(path) {
      return process.env.APP_URL + '/' + path
    },
    removeById(id) {
      this.addedScreenshots = this.addedScreenshots.filter((addedScreenshot) => {
        return (addedScreenshot.id !== id)
      })

      this.setScreenshotIds()
    },
    setScreenshotIds() {
      let result = this.addedScreenshots.map(a => a.id)

      this.$nuxt.$emit('cheat-set-screenshot-ids', result)
    },
    setDateFilter(event) {
      this.filters.byDate = event.target.value
      if (this.filters.byDate === 'fresh') {
        this.sortByDateDesc()
      } else {
        this.sortByDateAsc()
      }
    },
    sortByDateAsc() {
      this.screenshots.sort((a, b) => {
        if (a.created < b.created) {
          return -1;
        }
        if (a.created > b.created) {
          return 1;
        }
        return 0;
      })
    },
    sortByDateDesc() {
      this.screenshots.sort((a, b) => {
        if (a.created > b.created) {
          return -1;
        }
        if (a.created < b.created) {
          return 1;
        }
        return 0;
      })
    },
    getFilteredScreenshots() {
      if (this.filters.name.length > 0) {
        return this.screenshots.filter((screenshot) => screenshot.path.includes(this.filters.name))
      } else {
        return this.screenshots
      }
    }
  },
  async fetch() {
    await this.$MediaFileService.images().then(images => this.screenshots = images)
  },
  created() {
    this.$nuxt.$on('update-added-screenshots', (screenshots) => {
      console.log(screenshots)
      this.addedScreenshots = []

      let screensForDb = JSON.parse(JSON.stringify(this.screenshots))
      screenshots.forEach((screenshot) => {
        let foundedScreenshotId = screensForDb.findIndex(o => o.id === screenshot.file_id)
        if (foundedScreenshotId !== -1) {
          this.add(screensForDb[foundedScreenshotId])
        }
      })

    })
    this.$nuxt.$on('clear-screenshots', (screenshots) => {
      this.addedScreenshots = []
    })
  },
  fetchOnServer: false
}
