//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Loader from "@/components/admin/ui/Loader";
import draggable from 'vuedraggable';

export default {
  name: "CheatsList",
  components: {Loader, draggable},
  data() {
    return {
      cheats: []
    }
  },
  methods: {
    async sort(cheats) {
      cheats.forEach((cheat, index) => cheat.position = index + 1);
      await this.$CheatService.sort(cheats);
    },
    getOnUpdateText(onUpdate) {
      return (onUpdate) ? 'Убрать с обновления' : 'Поставить на обновление'
    },
    setOnUpdateState(cheat) {
      this.$nuxt.$CheatService.setOnUpdate(cheat.id).then(() => {
        this.$toast.success('Статус обновления чита изменён')
        this.$fetch()
      }).catch(() => {
        this.$toast.error('Ошибка изменения статуса обновления чита')
      })
    },
    deleteCheat(id) {
      this.$nuxt.$CheatService.delete(id).then(() => {
        this.$toast.success('Чит успешно удалён')
        this.$fetch()
      }).catch(() => {
        this.$toast.error('Ошибка удаления чита')
      })
    },
    editCheat(id) {
      this.$nuxt.$CheatService.view(id).then((cheat) => {
        this.$nuxt.$emit('edit-cheat', cheat)
      }).catch(() => {
      })
    }
  },
  async fetch() {
    this.cheats = await this.$CheatService.getAll()
  },

  created() {
    this.$nuxt.$on('refresh-cheats-list', () => this.$fetch())
  },
  fetchOnServer: false
}
