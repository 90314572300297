//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MetaTags from '@/components/admin/cheats/MetaTags'
import Advantages from '@/components/admin/cheats/Advantages'
import Screenshots from '@/components/admin/cheats/Screenshots'
import GeneralFields from '@/components/admin/cheats/GeneralFields'
import SystemRequirements from '@/components/admin/cheats/SystemRequirements'
import Features from '@/components/admin/cheats/Features'
import PricePlans from '@/components/admin/cheats/PricePlans'

export default {
  name: 'CreateCheat',
  data () {
    return {
      mode: 'create',
      id: null,
      url_path: '',
      games: [],
      game_id: 1,
      name: '',
      review_link: '',
      iframe_link: '',
      local_link: '',
      os: '',
      cpu: '',
      gpu: '',
      client: '',
      is_active: false,
      buy_in_crypto_link: '',
      is_country_dependency: false,
      card_color: 'purple',
      position: 0,
      translates: [
        {
          language_id: 1,
          heading: '',
          seo_text: '',
          meta_title: '',
          meta_description: '',
          instruction_link: ''
        },
        {
          language_id: 2,
          heading: '',
          seo_text: '',
          meta_title: '',
          meta_description: '',
          instruction_link: ''
        }
      ],
      advantages: [
        {
          'translates': [
            { name: '', language_id: 1 },
            { name: '', language_id: 2 },
          ]
        },
        {
          'translates': [
            { name: '', language_id: 1 },
            { name: '', language_id: 2 },
          ]
        },
        {
          'translates': [
            { name: '', language_id: 1 },
            { name: '', language_id: 2 },
          ]
        },

      ],
      screenshots: [],
      screenshot_ids: [],
      feature_categories: [
        {
          category_translates: [
            { name: '', language_id: 1 },
            { name: '', language_id: 2 }
          ],
          features: []
        }
      ],
      deleted_categories: [],
      deleted_features: [],
      price_plans: [
        {
          translates: [
            { cost: 0, days_count: 1, url: '', language_id: 1 },
            { cost: 0, days_count: 1, url: '', language_id: 2 },
          ]
        }
      ]
    }
  },
  methods: {
    create () {
      let newCheat = {
        name: this.name,
        url_path: this.url_path,
        review_link: this.review_link ?? '',
        iframe_link: this.iframe_link ?? '',
        local_link: this.local_link ?? '',
        os: this.os,
        cpu: this.cpu,
        gpu: this.gpu,
        client: this.client,
        game_id: this.game_id,
        is_country_dependency: this.is_country_dependency,
        translates: this.translates,
        advantages: this.advantages,
        screenshot_ids: this.screenshot_ids,
        feature_categories: this.feature_categories,
        price_plans: this.price_plans,
        buy_in_crypto_link: this.buy_in_crypto_link,
        card_color: this.card_color,
        position: this.position,
        is_active: this.is_active ? 1 : 0,
      }
      this.$nuxt.$CheatService.create(newCheat).then(() => {
        this.$toast.success('Чит успешно создан')
        this.$nuxt.$emit('refresh-cheats-list')
      }).catch(() => {
        this.$toast.error('Ошибка создания чита')
      })
    },
    edit () {
      let newCheatData = {
        name: this.name,
        url_path: this.url_path,
        id: this.id,
        review_link: this.review_link ?? '',
        iframe_link: this.iframe_link ?? '',
        local_link: this.local_link ?? '',
        os: this.os,
        cpu: this.cpu,
        gpu: this.gpu,
        client: this.client,
        game_id: this.game_id,
        is_country_dependency: this.is_country_dependency,
        translates: this.translates,
        advantages: this.advantages,
        screenshot_ids: this.screenshot_ids,
        feature_categories: this.feature_categories,
        price_plans: this.price_plans,
        deleted_features: this.deleted_features,
        deleted_categories: this.deleted_categories,
        buy_in_crypto_link: this.buy_in_crypto_link,
        card_color: this.card_color,
        position: this.position,
        is_active: this.is_active ? 1 : 0,
      }

      this.$nuxt.$CheatService.update(newCheatData).then(() => {
        this.$toast.success('Чит успешно обновлён')
        this.$nuxt.$emit('refresh-cheats-list')
      }).catch(() => {
        this.$toast.error('Ошибка обновления чита')
      })
    },
    clearForm () {
      this.mode = 'create'
      this.name = ''
      this.review_link = ''
      this.iframe_link = ''
      this.os = ''
      this.cpu = ''
      this.gpu = ''
      this.client = ''
      this.position = 0
      this.is_country_dependency = false
      this.deleted_categories = []
      this.deleted_features = []
      this.is_active = true

      this.translates = [
        {
          language_id: 1,
          heading: '',
          seo_text: '',
          meta_title: '',
          meta_description: '',
        },
        {
          language_id: 2,
          heading: '',
          seo_text: '',
          meta_title: '',
          meta_description: '',
        }
      ]
      this.advantages = [
        {
          'translates': [
            { name: '', language_id: 1 },
            { name: '', language_id: 2 },
          ]
        },
        {
          'translates': [
            { name: '', language_id: 1 },
            { name: '', language_id: 2 },
          ]
        },
        {
          'translates': [
            { name: '', language_id: 1 },
            { name: '', language_id: 2 },
          ]
        },

      ]
      this.screenshot_ids = []
      this.feature_categories = [
        {
          category_translates: [
            { name: '', language_id: 1 },
            { name: '', language_id: 2 }
          ],
          features: []
        }
      ]
      this.price_plans = [
        {
          translates: [
            { cost: 0, days_count: 1, url: '', language_id: 1 },
            { cost: 0, days_count: 1, url: '', language_id: 2 },
          ]
        }
      ]

      this.$nuxt.$emit('clear-screenshots')
    }
  },
  components: { Features, MetaTags, Screenshots, Advantages, SystemRequirements, GeneralFields, PricePlans },
  mounted () {
    /* General Fields */
    this.$nuxt.$on('cheat-change-name', (name) => {
      this.name = name
    })
    this.$nuxt.$on('cheat-change-url-path', (url_path) => {
      this.url_path = url_path
    })
    this.$nuxt.$on('cheat-change-review-link', (review_link) => {
      this.review_link = review_link
    })
    this.$nuxt.$on('cheat-change-iframe-link', (iframe_link) => {
      this.iframe_link = iframe_link
    })
    this.$nuxt.$on('cheat-change-local-link', (local_link) => {
      this.local_link = local_link
    })

    /* System Requirements */
    this.$nuxt.$on('cheat-change-os', (os) => {
      this.os = os
    })
    this.$nuxt.$on('cheat-change-cpu', (cpu) => {
      this.cpu = cpu
    })
    this.$nuxt.$on('cheat-change-gpu', (gpu) => {
      this.gpu = gpu
    })
    this.$nuxt.$on('cheat-change-client', (client) => {
      this.client = client
    })

    /* Set Screenshot Ids */
    this.$nuxt.$on('cheat-set-screenshot-ids', (screenshot_ids) => {
      this.screenshot_ids = screenshot_ids
    })

    this.$nuxt.$on('change-price-plans', (price_plans) => {
      this.price_plans = price_plans
    })
  },
  created () {
    this.$nuxt.$on('edit-cheat', (cheat) => {
      this.mode = 'edit'
      this.id = cheat.id
      this.name = cheat.name
      this.url_path = cheat.url_path
      this.game_id = cheat.game_id
      this.review_link = cheat.review_link
      this.iframe_link = cheat.iframe_link
      this.local_link = cheat.local_link
      this.os = cheat.os
      this.cpu = cheat.cpu
      this.gpu = cheat.gpu
      this.client = cheat.client
      this.is_country_dependency = cheat.is_country_dependency
      this.translates = cheat.translates
      this.advantages = cheat.advantages
      this.feature_categories = cheat.feature_categories
      this.price_plans = cheat.price_plans
      this.deleted_features = []
      this.deleted_categories = []
      this.card_color = cheat.card_color
      this.buy_in_crypto_link = cheat.buy_in_crypto_link
      this.position = cheat.position
      this.is_active = cheat.is_active
      this.$nuxt.$emit('update-added-screenshots', cheat.screenshots)
    })

    this.$nuxt.$on('remove-feature-category', (indexToDelete => {
      this.feature_categories = this.feature_categories.filter((category, index) => {
        return (index !== indexToDelete)
      })
    }))

    this.$nuxt.$on('change-feature', (features => {
      console.log(features);
      this.feature_categories = features;
    }))
  },
  async fetch () {
    this.games = await this.$GameService.getAll()
  },
}
