//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MediaFilesModal from "@/components/admin/posts/MediaFilesModal";

export default {
  name: "ContentEditor",
  components: {MediaFilesModal},
  props: {
    id: String,
    content: String,
    label: String,
    lang: String
  },
  data() {
    return {
      selectStart: 0,
      selectEnd: 0,
      contentValue: (this.content === null) ? '' : this.content,
      isModalOpen: false
    }
  },

  methods: {
    setSelectIndexes() {
      let textarea = this.$refs.textarea
      this.selectStart = textarea.selectionStart
      this.selectEnd = textarea.selectionEnd
    },
    clearSelectIndexes() {
      this.selectStart = 0
      this.selectEnd = 0
    },
    insertSubStr(main_string, ins_string, pos) {
      if (main_string == null) {
        main_string = ''
      }
      if (typeof (pos) == "undefined") {
        pos = 0;
      }
      if (typeof (ins_string) == "undefined") {
        ins_string = '';
      }
      return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
    },
    insertHTML(current_string, open_tag, close_tag) {
      let newString = this.insertSubStr(current_string, open_tag, this.selectStart)
      return this.insertSubStr(newString, close_tag, this.selectEnd + open_tag.length)
    },
    findNearHTMLTag(str) {
      let regex = /(<([^>]+)>)/ig
      regex.exec(str)
      return regex.lastIndex
    },
    insertStyleToNearTag(current_string, style) {
      let styleAttribute = ' style="' + style + '"'
      let textBeforeSelecting = current_string.substr(0, this.selectStart)
      let nearTagId = this.findNearHTMLTag(textBeforeSelecting)

      if (nearTagId === 0) {
        let startOfParagraphWithStyle = '<p' + styleAttribute + '>';
        let endOfParagraphWithStyle = '</p>';
        let newString = this.insertSubStr(current_string, startOfParagraphWithStyle, 0)
        return this.insertSubStr(newString, endOfParagraphWithStyle, newString.length)
      } else {
        return this.insertSubStr(current_string, styleAttribute, nearTagId - 1)
      }

    },
    setBold() {
      this.setSelectIndexes()
      this.contentValue = this.insertHTML(this.contentValue, '<b>', '</b>')
      this.clearSelectIndexes()
    },
    setItalic() {
      this.setSelectIndexes()
      this.contentValue = this.insertHTML(this.contentValue, '<i>', '</i>')
      this.clearSelectIndexes()
    },
    setHeading(level) {
      this.setSelectIndexes()
      this.contentValue = this.insertHTML(this.contentValue, '<h' + level + '>', '</h' + level + '>')
      this.clearSelectIndexes()
    },
    setTextPosition(position) {
      this.setSelectIndexes()
      this.contentValue = this.insertStyleToNearTag(this.contentValue, 'text-align: ' + position + ';')
    },
    addMediaFile() {
      this.isModalOpen = true
    },
    setImage(url) {
      this.contentValue += '<img class="img-fluid" src="' + url + '" alt="">'
    },
    changeContentEmit() {

    }
  },
  watch: {
    contentValue() {
      this.$nuxt.$emit('change-content', {content: this.contentValue, id: this.id, lang: this.lang})
    }
  },
  created() {
    this.$nuxt.$on('close-media-files-modal', () => {
      this.isModalOpen = false
    })
    this.$nuxt.$on('file-to-attach', (file) => {
      this.isModalOpen = false
      this.setImage(file.path)
    })

  }
}
