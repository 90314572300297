//
//
//
//
//
//

export default {
  name: "ButtonEdit",
  props: {path: String}

}
