//
//
//
//
//
//
//
//
//

export default {
  name: "FileAdditionZone",
  data() {
    return {
      fileTypes: 'image/*, video/*',
      media: null,
      isCreateAvailable: false
    }
  },
  methods: {
    async createFile() {
      if (this.media !== null) {

        for(const file of this.media) {
          let formData = new FormData();
          formData.append('media', file);
          await this.$MediaFileService.create(formData);
        }

        this.clearData()
        this.$toast.success('Файл успешно создан')
        this.emitToUpdateFileList()
        this.isCreateAvailable = false
        return true
      }
      this.$toast.error('Ошибка')
      return false
    },
    clearData() {
      this.media = null
      this.$refs.file.value = null
    },
    handleFileUpload() {
      this.media = this.$refs.file.files;
      this.isCreateAvailable = true
    },
    emitToUpdateFileList() {
      setTimeout(() => {
        this.$nuxt.$emit('media-file-create')
      }, 300)
    }
  }
}
