//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Advantages",
  props: {
    advantages: {
      type: Array,
      default: () => ([
        {
          'translates': [
            {name: '', language_id: 1},
            {name: '', language_id: 2},
          ]
        },
        {
          'translates': [
            {name: '', language_id: 1},
            {name: '', language_id: 2},
          ]
        },
        {
          'translates': [
            {name: '', language_id: 1},
            {name: '', language_id: 2},
          ]
        },
      ])
    }
  },

}
