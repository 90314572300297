//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "PricePlans",
  props: {
    price_plans: {
      type: Array,
      default: () => ([
        {
          translates: [
            {cost: 0, days_count: 0, url: '', language_id: 1},
            {cost: 0, days_count: 0, url: '', language_id: 2},
          ]
        }
      ])
    }
  },
  methods: {
    addPricePlan() {
      this.price_plans.push({
        translates: [
          {cost: 0, days_count: 0, url: '', language_id: 1},
          {cost: 0, days_count: 0, url: '', language_id: 2},
        ]
      })
      this.changePricePlanInParent()
    },
    removePricePlan() {
      if(this.price_plans.length === 0) return
      this.price_plans.pop()
      this.changePricePlanInParent()
    },
    changePricePlanInParent() {
      this.$nuxt.$emit('change-price-plans', this.price_plans)
    }
  }
}
