//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "MetaTags",
  props: {
    translates: {
      type: Array,
      default: () => ([
        {
          lang_id: 1,
          meta_title: '',
          meta_description: '',
          heading: '',
          description: '',
          seo_text: '',
          instruction_link: ''
        },
        {
          lang_id: 2,
          meta_title: '',
          meta_description: '',
          heading: '',
          description: '',
          seo_text: '',
          instruction_link: ''
        }
      ])
    }
  },
  methods: {
    getLocaleIcon(landId) {
      let iconPath = ''
      this.$i18n.locales.forEach((item, i) => {
        if (i + 1 === landId) {
          iconPath = item.icon_path
        }
      })
      return iconPath
    }
  }
}
