//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Login',
  data () {
    return {
      form: {
        email: '',
        password: ''
      }
    }

  },
  methods: {
    login () {
      this.$auth.loginWith('laravelJWT', {
        data: this.form
      }).then((res) => {
        console.log('', res)
        this.$auth.setUserToken(res.data['access_token'], true).then(() => {
          console.log('logged in!')
        })
      })
    }
  }
}
