//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "GeneralFields",
  props: {
      name: String,
      short_name: String,
      url: String,
  },
  watch: {
    name: function (newValue) {
      this.$nuxt.$emit('game-change-name', newValue)
    },
    short_name: function (newValue) {
      this.$nuxt.$emit('game-change-short-name', newValue)
    },
    url: function (newValue) {
      this.$nuxt.$emit('game-change-url', newValue)
    }
  },
}
