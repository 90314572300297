//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "CategoriesList",
  data() {
    return {
      categories: []
    }
  },
  async fetch() {
    this.categories = await this.$CategoryService.getAll()
  },
  methods: {
    async deleteCategory(id) {
      if (await this.$CategoryService.delete(id)) {
        setTimeout(() => {
          this.$fetch()
        }, 300)
        this.$toast.success('Категория удалена')
      } else {
        this.$toast.error('Ошибка удаления категории')
      }

    },
  },
  created() {
    this.$nuxt.$on('category-create', () => {
      this.$fetch()
    })
  }
}
