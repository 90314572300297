//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import draggable from 'vuedraggable';

export default {
  name: "Features",
  components: {draggable},
  props: {
    deleted_categories: Array,
    deleted_features: Array,
    feature_categories: {
      type: Array,
      default: () => ([
        {
          category_translates: [
            {name: '', language_id: 1},
            {name: '', language_id: 2}
          ],
          features: [
            {
              translates: [
                {name: 'ВХ - видны враги', language_id: 1},
                {name: 'Wh - see enemies', language_id: 2}
              ]
            }
          ]
        }
      ])
    }
  },
  methods: {
    end() {
      this.$nuxt.$emit('change-feature', this.feature_categories)
    },
    addFeatureCategory() {
      this.feature_categories.push({
        category_translates: [
          {name: '', language_id: 1},
          {name: '', language_id: 2}
        ],
        features: []
      })
    },
    addFeature(categoryId) {
      let ruText = this.$refs['featureField' + categoryId + 'RU'][0].value
      let enText = this.$refs['featureField' + categoryId + 'EN'][0].value

      this.feature_categories[categoryId].features.push({
        translates: [
          {name: ruText, language_id: 1},
          {name: enText, language_id: 2},
        ]
      })
    },

    removeCategory(categoryId) {
      this.feature_categories.forEach((category, index) => {
        if (categoryId === index) {
          if (category.hasOwnProperty('category_id')) {
            this.deleted_categories.push(category.category_id)
          }
          this.$nuxt.$emit('remove-feature-category', index)
        }
      })
    },
    removeFeature(categoryId, featureId) {
      this.feature_categories[categoryId].features = this.feature_categories[categoryId].features.filter((feature, index) => {
        if (index !== featureId) {
          return true
        } else {
          if (feature.hasOwnProperty('feature_id')) {
            this.deleted_features.push(feature.feature_id)
          }
          return false
        }
      })
    }
  }
}
