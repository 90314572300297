//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonEdit from "@/components/admin/buttons/ButtonEdit";
import PageService from "../../../plugins/services/PageService";

export default {
  name: "PagesList",
  components: {ButtonEdit},
  data() {
    return {
      posts: []
    }
  },
  methods: {
    async loadPostToEdit(id) {
      let postForEdit = await this.$PageService.view(id)

      if (postForEdit !== false) {
        this.$nuxt.$emit('edit-page', postForEdit)
      } else {
        this.$nuxt.$toast.info('Не удалось загрузить пост для редактирования')
      }
    },
    async deletePost(id) {
      let isDeleted = await this.$PageService.delete(id)

      if (isDeleted) {
        this.$toast.success('Пост успешно удалён')
        this.$fetch()
      } else {
        this.$toast.error('Ошибка удаления поста')
      }
    }
  },
  async fetch() {
    this.posts = await this.$PageService.getAll()
  },
  created() {
    this.$nuxt.$on('page-created', () => {
      this.$fetch()
    })
  }
}
