//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Loader from "@/components/admin/ui/Loader";
import draggable from 'vuedraggable';

export default {
  name: "GamesList",
  components: {Loader, draggable},
  data() {
    return {
      games: []
    }
  },
  filters: {
    is_active: function (value) {
      return (value) ? '<span class="active-game">Активна</span>' : '<span class="not-active-game">Выключена</span>'
    }
  },
  methods: {
    async sort() {
      this.games.forEach((game, index) => game.position = index + 1)
      await this.$GameService.sort(this.games);
    },
    emitToEditGame(id) {
      this.$nuxt.$emit('game-edit', id)
    },
    async deleteGame(id) {
      if (await this.$GameService.delete(id)) {
        this.$toast.success('Успешно удалено')
        await this.refresh()
      } else {
        this.$toast.error('Ошибка удаления')
      }
    },
    async refresh() {
      this.$fetch()
    }
  },
  async fetch() {
    this.games = await this.$GameService.getAll()
  },
  mounted() {
    this.$nuxt.$on('game-created', () => {
      this.refresh()
    })
  }
}
