//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageTranslatableFields from "@/components/admin/pages/PageTranslatableFields";
import Multiselect from 'vue-multiselect'


export default {
  name: "CreatePageForm",
  components: {PageTranslatableFields, Multiselect},
  data() {
    return {
      mode: 'create',
      page_id: null,
      active_tab: 'rus',
      url: '',
      russian: {
        title: null,
        content: null,
        description: null,
        meta_title: null,
        meta_description: null,
        language_id: 1
      },
      english: {
        title: null,
        content: null,
        description: null,
        meta_title: null,
        meta_description: null,
        language_id: 2
      },
    }
  },
  methods: {
    clearForm() {
      this.mode = 'create'
      this.page_id = -1
      this.page_id = null
      this.url = ''
      this.russian = {
        title: null,
        content: null,
        description: null,
        meta_title: null,
        meta_description: null,
        language_id: 1
      }
      this.english = {
        title: null,
        content: null,
        description: null,
        meta_title: null,
        meta_description: null,
        language_id: 2
      }
    },
    refreshCurrentPage() {
      this.$router.go(this.$router.currentRoute)
    },
    async createPost() {
      let isCreated = await this.$PageService.create({
        author_id: 1,
        url: this.url,
        translates: [
          this.russian, this.english
        ]
      })

      if (isCreated) {
        this.$toast.success('Пост успешно создан')
        this.refreshCurrentPage()
      } else {
        this.$toast.error('Ошибка создания поста')
      }
    },
    async editPost() {
      this.russian.page_id = this.page_id
      this.english.page_id = this.page_id
      let isEdited = await this.$PageService.edit({
        id: this.page_id,
        url: this.url,
        author_id: 1,
        translates: [
          this.russian, this.english
        ]
      })
      if (isEdited) {
        this.$toast.success('Пост успешно изменён')
        this.clearForm()
        this.$nuxt.$emit('page-created')
      } else {
        this.$toast.error('Не удалось отправить изменения. Возможно, есть пустые поля')
      }

    }
  },
  created() {
    this.$nuxt.$on('change-page-content', (data) => {
      if (data.lang === 'rus') {
        this.russian.content = data.content
      } else {
        this.english.content = data.content
      }
    })

    this.$nuxt.$on('edit-page', (postForEdit => {
      this.page_id = postForEdit.id
      this.url = postForEdit.url
      this.author_id = postForEdit.author_id
      this.russian = postForEdit.translates[0]
      this.english = postForEdit.translates[1]

      this.mode = 'edit'
    }))
  }
}
