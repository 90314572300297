//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SystemRequirements",
  props: {
    os: String,
    cpu: String,
    gpu: String,
    client: String,
  },
  methods: {
    changeOs: function (e) {
      this.$nuxt.$emit('cheat-change-os', e.target.value)
    },
    changeCpu: function (e) {
      this.$nuxt.$emit('cheat-change-cpu', e.target.value)
    },
    changeGpu: function (e) {
      this.$nuxt.$emit('cheat-change-gpu', e.target.value)
    },
    changeClient: function (e) {
      this.$nuxt.$emit('cheat-change-client', e.target.value)
    }
  },
}
