//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "GeneralFields",
  props: {
    name: String,
    review_link: String,
    iframe_link: String,
    url_path: String,
    local_link: String
  },
  methods: {
    changeName: function (e) {
      this.$nuxt.$emit('cheat-change-name', e.target.value)
    },
    changeUrlPath: function (e) {
      this.$nuxt.$emit('cheat-change-url-path', e.target.value)
    },
    changeReviewLink: function (e) {
      this.$nuxt.$emit('cheat-change-review-link', e.target.value)
    },
    changeIframeLink: function (e) {
      this.$nuxt.$emit('cheat-change-iframe-link', e.target.value)
    },
    changeLocalLink: function (e) {
      this.$nuxt.$emit('cheat-change-local-link', e.target.value)
    }
  },
}
