//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SecondaryTexts",
  props: {
    translates: {
      type: Array,
      default: () => ([
        {
          lang_id: 1,
          meta_title: '',
          meta_description: '',
          heading: '',
          description: '',
          seo_text: ''
        },
        {
          lang_id: 2,
          meta_title: '',
          meta_description: '',
          heading: '',
          description: '',
          seo_text: ''
        }
      ])
    }
  },
}
