//
//
//
//
//
//

export default {
  name: "ButtonCreate",
  props: {path: String}
}
