//
//
//
//
//
//

export default {
  name: "ButtonList",
  props: { path: String }
}
