//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Loader from "@/components/admin/ui/Loader";

export default {
  name: "FileList",
  components: {Loader},
  props: {
    forPostEditor: Boolean
  },
  data() {
    return {
      files: [],
      meta: null,
      selectedPage: 1,
      maxSize: 10
    }
  },
  async fetch() {
    await this.loadFilesData(1)
  },
  methods: {
    async deleteFile(id) {
      if (await this.$MediaFileService.delete(id)) {
        setTimeout(() => {
          this.$fetch()
        }, 300)
        this.$toast.success('Файл удалён')
      } else {
        this.$toast.error('Ошибка удаления файла')
      }

    },
    async loadFilesData(page = this.selectedPage) {
      this.selectedPage = page;
      let filesData = await this.$MediaFileService.getWithPagination(this.selectedPage)
      this.files = filesData.files
      this.meta = filesData.meta
    },
    emitFile(file) {
      if (this.forPostEditor === true)
        this.$nuxt.$emit('file-to-attach', file)
    },
    isImage(file) {
      return (file.type.includes('image'))
    },
    isVideo(file) {
      return (file.type.includes('video'))
    },
    getFileUrl(file) {
      if (process.env.APP_ENV === 'local') {
        return 'https://wh-satano.ru/' + file.path
      } else {
        return file.path
      }
    }
  },
  async created() {
    this.$nuxt.$on('media-file-create', () => {
      this.$fetch()
    })
  },
  computed: {
    pages() {
      let start = Math.max(this.selectedPage - (Math.ceil(this.maxSize / 2)), 1);
      let end = Math.min(start + (this.maxSize - 1), this.meta.last_page);
      start = Math.max(end - (this.maxSize - 1), 1);
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    }
  },
}
