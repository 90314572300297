//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "CreateCategoryForm",
  data() {
    return {
      translates: [
        {
          name: null,
          language_id: 1
        },
        {
          name: null,
          language_id: 2
        }
      ]
    }
  },
  methods: {
    async createCategory() {
      if (this.translates[0].name !== null && this.translates[1].name !== null) {
        if (await this.$CategoryService.create(this.translates)) {
          this.clearData()
          this.$toast.success('Категория успешно создана')
          this.emitToUpdateCategoryList()
        } else {
          this.$toast.error('Ошибка создания категории. Возможно Вы вводили названия длиной менее 2-х символов')
        }
      } else {
        this.$toast.error('Заполните все поля')
      }

    },
    clearData() {
      this.translates[0].name = null
      this.translates[1].name = null
    },
    emitToUpdateCategoryList() {
      setTimeout(() => {
        this.$nuxt.$emit('category-create')
      }, 300)
    }
  }

}
