//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MetaTags from "@/components/admin/games/MetaTags"
import GeneralFields from "@/components/admin/games/GeneralFields"
import SecondaryTexts from "@/components/admin/games/SecondaryTexts"

export default {
  name: "CreateGame",
  components: {SecondaryTexts, MetaTags, GeneralFields},
  data() {
    return {
      mode: 'create',
      id: null,
      name: '',
      short_name: '',
      url_path: '',
      icon_path: '',
      position: 0,
      is_active: false,
      translates: [
        {
          language_id: 1,
          meta_title: '',
          meta_description: '',
          heading: '',
          description: '',
          seo_text: ''
        },
        {
          language_id: 2,
          meta_title: '',
          meta_description: '',
          heading: '',
          description: '',
          seo_text: ''
        }
      ]
    }
  },
  methods: {
    async createGame() {
      let data = this.getDataForRequest()
      if (this.validateData(data)) {
        await this.$nuxt.$GameService.create(data)
        this.$nuxt.$emit('game-created')
        this.$toast.success('Игра успешно добавлена')
      }
    },
    async editGame() {
      let data = this.getDataForRequest()
      if (this.validateData(data)) {
        await this.$nuxt.$GameService.edit(data)
        this.$nuxt.$emit('game-created')
        this.$toast.success('Игра успешно изменена')
        this.clearData()
      }
    },
    initEditingElement(id) {
      this.mode = 'edit'
      this.id = id
      this.setGameById(id)
    },
    getDataForRequest() {
      return {
        id: this.id,
        name: this.name,
        short_name: this.short_name,
        url_path: this.url_path,
        icon_path: this.icon_path,
        is_active: this.is_active,
        position: this.position,
        translates: this.translates,
      }
    },
    validateData(data) {
      for (let key in data) {
        if (typeof data[key] === 'string' && data[key].length < 1) {
          this.$toast.info('Заполните поле: ' + key)

          return false
        } else if (Array.isArray(data[key])) {
          for (let i = 0; i < data[key].length; i++) {
            for (let translatesObjectKey in data[key][i]) {
              if (typeof data[key][i][translatesObjectKey] === 'string' && data[key][i][translatesObjectKey].length < 1) {
                this.$toast.info('Заполните поле: ' + translatesObjectKey)

                return false
              }
            }
          }
        }
      }

      return true
    },
    async setGameById(id) {
      const game = await this.$GameService.view(id)
      this.name = game.name
      this.short_name = game.short_name
      this.url_path = game.url_path
      this.icon_path = game.icon_path
      this.position = game.position
      this.is_active = game.is_active
      this.translates = [
        {
          language_id: game.translates[0].language_id,
          meta_title: game.translates[0].meta_title,
          meta_description: game.translates[0].meta_description,
          heading: game.translates[0].heading,
          description: game.translates[0].description,
          seo_text: game.translates[0].seo_text
        },
        {
          language_id: 2,
          meta_title: game.translates[1].meta_title,
          meta_description: game.translates[1].meta_description,
          heading: game.translates[1].heading,
          description: game.translates[1].description,
          seo_text: game.translates[1].seo_text
        }
      ]
    },
    clearData() {
      this.mode = 'create'
      this.name = ''
      this.short_name = ''
      this.url_path = ''
      this.icon_path = ''
      this.position = 0
      this.is_active = false
      this.translates = [
        {
          language_id: 1,
          meta_title: '',
          meta_description: '',
          heading: '',
          description: '',
          seo_text: ''
        },
        {
          language_id: 2,
          meta_title: '',
          meta_description: '',
          heading: '',
          description: '',
          seo_text: ''
        }
      ]
    }
  },
  created() {
    this.$nuxt.$on('game-change-name', (name) => {
      this.name = name
    })
    this.$nuxt.$on('game-change-short-name', (name) => {
      this.short_name = name
    })
    this.$nuxt.$on('game-change-url', (name) => {
      this.url_path = name
    })
    this.$nuxt.$on('game-edit', (id) => {
      this.initEditingElement(id)
    })
  }
}
